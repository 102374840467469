import { Dispatch } from 'redux';
import { createModel } from '@rematch/core';
import { login, getUserData } from '../repositories/auth';
import { RootState, RematchDispatch } from '.';
import { createSelector } from 'reselect';
import { ApiError } from '../repositories/ApiError';
import { produce } from 'immer';
import { setToken as setAxiosToken } from '../repositories/axios';

export interface AffiliateUser {
  _id: string;
  username: string;
  affiliateName: string;
  firstTimeLogin: boolean;
  phoneNumber: string;
}

export interface AuthState {
  token?: string;
  data?: AffiliateUser;
  error?: ApiError;
}

const initialState: AuthState = {
  token: undefined,
  data: undefined,
  error: undefined,
};

const auth = createModel({
  name: 'auth',
  state: initialState,
  reducers: {
    setToken: (state: AuthState, payload: string) => {
      return produce(state, draft => {
        draft.error = undefined;
        draft.token = payload;
      });
    },
    setUser: (state: AuthState, payload: AffiliateUser) => {
      return produce(state, draft => {
        draft.error = undefined;
        draft.data = payload;
      });
    },
    setError(state: AuthState, payload: any): AuthState {
      return produce(state, draft => {
        draft.error = payload;
      });
    },
    logout(): AuthState {
      return initialState;
    },
  },
  effects: {
    async login(payload: { merchantId: string; password: string }) {
      const { data, error } = await login(payload.merchantId, payload.password);
      if (data) {
        var values = new Array();
        var oneday = new Date();
        oneday.setHours(oneday.getHours() + 24); //one day from now
        values.push("hello world");
        values.push(oneday);
        localStorage.setItem('0', values.join(";"))
        localStorage.setItem('idealMerchantinfo', JSON.stringify(data.data))
        localStorage.setItem('idealMerchantDBId', data.data._id)
        localStorage.setItem('idealMerchantId', data.data.merchantId)
        this.setToken(data.data._id);
        this.setUser(data.data.merchantName);
        setAxiosToken(data.data._id);
        window.location.reload();
      } else {
        this.setError(error);
      }
    },
    async getMe() {
      const { data, error } = await getUserData();

      if (data) {
        this.setUser(data);
      } else {
        this.setError(error);
      }
    },
  },
});

const userSelector = (state: RootState) => state.auth.data;
const isAuthenticatedSelector = createSelector(userSelector, user => !!user);
const tokenSelector = (state: RootState) => state.auth.token;
const errorSelector = (state: RootState) => state.auth.error;
const isLoggingInSelector = (state: RootState): boolean =>
  (state as any).loading.effects.auth.login;

export const authSelector = {
  userSelector,
  tokenSelector,
  errorSelector,
  isLoggingInSelector,
  isAuthenticatedSelector,
};

export const authDispatcher = (dispatch: Dispatch) => {
  const authDispatch = dispatch as RematchDispatch;
  return {
    login: async (merchantId: string, password: string) => {
      authDispatch.auth.login({ merchantId, password });
    },
    logout: () => {
      authDispatch.auth.logout();
      localStorage.clear();
      window.location.reload();
    },
    getMe: () => {
      //authDispatch.auth.getMe();
    }
  };
};

export default auth;
