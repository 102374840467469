export default {
  items: [
   {
      name: "Settings",
      url: "/settings",
      icon: "fa fa-ticket"
    },
    {
      name: "Product",
      url: "/product/listproduct",
      icon: "fa fa-archive"
    },
    {
      name: "Order",
      url: "/orders/listorders",
      icon: "fa fa-dollar"
    }
  ]
};
