import React from 'react';
import DefaultLayout from './containers/DefaultLayout';
import LoadingCircular from './components/Loading/LoadingCircular';
import Loadable from 'react-loadable';

function Loading() {
  return <LoadingCircular />;
}

const ResetPassword = Loadable({
  loader: () => import('./views/ResetPasswordSetting/ResetPassword'),
  loading: Loading,
})

const ProductIndex = Loadable({
  loader: () => import('./views/Merchant/Product/ProductIndex'),
  loading: Loading,
})

const AddNewProduct = Loadable({
  loader: () => import('./views/Merchant/Product/AddNewProduct'),
  loading: Loading,
})

const TransactionIndex = Loadable({
  loader: () => import('./views/Merchant/Order/OrderIndex'),
  loading: Loading,
})

const Settings = Loadable({
  loader: () => import('./views/Merchant/Settings/Settings'),
  loading: Loading,
})

const Profile = Loadable({
  loader: () => import('./views/Merchant/Profile/Profile'),
  loading: Loading,
})

const OrderDetail = Loadable({
  loader: () => import('./views/Merchant/Order/OrderDetail/OrderDetailHome'),
  loading: Loading,
})

export interface RouteItem {
  path: string;
  exact?: Boolean;
  name: string;
  component: any;
}

const routeItems: RouteItem[] = [
  { path: '/', exact: true, name: 'Home', component: DefaultLayout },
  {
    path: '/product/listproduct',
    name: 'List Product',
    exact: true,
    component: ProductIndex,
  },
  {
    path: '/product/listproduct/new',
    name: 'Product',
    exact: true,
    component: AddNewProduct,
  },
  {
    path: '/product/listproduct/edit/:productid',
    name: 'Product',
    exact: true,
    component: AddNewProduct,
  },
  {
    path: '/orders/listorders',
    name: 'List Orders',
    exact: true,
    component: TransactionIndex,
  },
  {
    path: '/orders/listorders/orderdetail/:orderid/:settlementid',
    name: 'Orders',
    exact: true,
    component: OrderDetail,
  },
  {
    path: '/settings/reset-password',
    name: 'Reset Password',
    exact: true,
    component: ResetPassword,
  },
  {
    path: '/profile',
    name: 'Profile',
    exact: true,
    component: Profile,
  },
  {
    path: '/settings',
    name: 'Settings',
    exact: true,
    component: Settings,
  }
];

export default routeItems;
