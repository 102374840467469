import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppAside,
  AppBreadcrumb,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
import navigation from '../../_nav';
import routes from '../../router';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

const DefaultLayout = (props) => {
  let permission = [];
  const PrivateRoute = ({ isAllowed, ...props }) => {
    return isAllowed ? <Route {...props} /> : <Redirect to="/product/listproduct" />;
  };
  const items = [];
  navigation.items.map(nav => {
    let obj = null;
    if (nav.permission) {
      if (permission[nav.permission]) {
        obj = {};
        obj.name = nav.name;
        obj.url = nav.url;
        obj.icon = nav.icon;
      }
    } else {
      obj = {};
      obj.name = nav.name;
      obj.url = nav.url;
      obj.icon = nav.icon;
    }
    let ok = false;
    nav.permission && permission[nav.permission] ? (ok = true) : (ok = false);
    if (!nav.permission && !ok) {
      ok = true;
    }

    if (ok && nav.children) {
      obj.children = [];
      nav.children.map(child => {
        if (child.permission) {
          let childObj = null;
          if (permission[child.permission]) {
            childObj = {};
            childObj.name = child.name;
            childObj.url = child.url;
            childObj.icon = child.icon;
          }

          if (childObj) {
            obj.children.push(childObj);
          }
        } else {
          let childObj = {};
          childObj.name = child.name;
          childObj.url = child.url;
          childObj.icon = child.icon;
          obj.children.push(childObj);
        }
      });
    }

    ok = false;

    if (nav.children) {
      if (obj && obj.children.length <= 0) {
        ok = false;
      } else {
        ok = true;
      }
    }
    if (!nav.children) {
      ok = true;
    }

    if (ok && obj) {
      items.push(obj);
    }
  });

  let navItems = {};
  navItems.items = items;
  return (
    <div className="app">
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <AppSidebarNav navConfig={navItems} {...props} />
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main">
          <AppBreadcrumb appRoutes={routes} />
          <Container fluid className="p-lr-15">
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  route.permission ? (
                    <PrivateRoute
                      isAllowed={true}
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  ) : (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => <route.component {...props} />}
                    />
                  )
                ) : null;
              })}
              <Redirect from="/" to="/product/listproduct" />
            </Switch>
          </Container>
        </main>
        <AppAside fixed hidden>
          <DefaultAside />
        </AppAside>
      </div>
      <AppFooter>
        <DefaultFooter />
      </AppFooter>
    </div>
  );
};

export default DefaultLayout;
